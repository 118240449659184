/**
 * @file Reads the dir attribute from the html object to detect the direction.
 */
const dir = (): 'ltr' | 'rtl' => {
  if (document.documentElement.dir === 'rtl') return 'rtl'
  if (document.documentElement.dir === 'ltr') return 'ltr'
  if (document.documentElement.attributes['data-dir']?.value === 'rtl') return 'rtl'
  if (document.documentElement.attributes['data-dir']?.value === 'ltr') return 'ltr'
  return 'ltr'
}

const setDirAttribute = (dir: 'ltr' | 'rtl'): void => {
  document.documentElement.dir = dir
  document.documentElement.setAttribute('data-dir', dir)
}

export { dir, setDirAttribute }
